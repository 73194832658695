.image-card {
    margin-bottom: 20px;
}

.image-card img {
    object-fit: cover;
    height: 128px;
    width: 128px;
}

.modal-card {
    z-index: 1050;
    /* Ensure the modal is on top */
}

.modal-background {
    z-index: 1000;
    /* Background of the modal */
}

.leaflet-control {
    z-index: 0 !important
}

.leaflet-pane {
    z-index: 0 !important
}

.leaflet-top,
.leaflet-bottom {
    z-index: 0 !important
}