html, body {
    height: 100%;
    margin: 0;
}

#root { /* Assuming your app root id is 'root' */
    display: flex;
    flex-direction: column;
    height: 100%;
}

.footer {
    margin-top: auto; /* Pushes the footer to the bottom of the viewport/page */
    width: 100%;
}
