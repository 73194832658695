@media (max-width: 1024px) {
    .navbar {
        display: flex;
        flex-direction: row;
        box-shadow: none;
        justify-content: space-between;
    }
}

@media (max-width: 1024px) {
    .navbar-start {
        display: flex;
        align-items: stretch;
        box-shadow: none;
        justify-content: flex-start;
        margin-left: auto;
        padding-top: .5rem;
    }
}

@media (max-width: 1024px) {
    .navbar-menu {
        display: flex;
        margin-right: auto;
        box-shadow: none;
        justify-content: flex-end;
        padding: 0 0;
        max-width: 100%;
        flex-wrap: wrap;
    }
}

@media (max-width: 1024px) {
    .navbar-end {
        display: flex;
        margin-right: auto;
        box-shadow: none;
        justify-content: flex-end;
    }
}

.geojson-container {
    position: relative;
    width: 100%;
    height: 500px;
    overflow: hidden;
}

.geojson-iframe {
    position: absolute;
    top: -100px;
    /* Adjust this value to control vertical clipping */
    left: -50px;
    /* Adjust this value to control horizontal clipping */
    width: 120%;
    /* Adjust this value to ensure the iframe covers the container */
    height: 700px;
    /* Adjust this value to ensure the iframe covers the container */
    border: none;
}